.calendar .fc .fc-button-primary,
.calendar .fc .fc-button-primary:active:hover,
.calendar .fc .fc-button-primary:not(:disabled):active:hover,
.calendar .fc .fc-button-primary:not(:disabled):active,
.calendar .fc .fc-button-primary:hover,
.calendar .fc .fc-button-primary:focus,
.calendar .fc .fc-button-primary:disabled {
    background: none;
    border: none;
    box-shadow: none;
    color: #59C8FA;
    font-weight: bold;
    height: 60px;
}

.calendar .fc .fc-button-primary.fc-next-button,
.calendar .fc .fc-button-primary.fc-next-button:active:hover,
.calendar .fc .fc-button-primary.fc-prev-button,
.calendar .fc .fc-button-primary.fc-prev-button:active:hover {
    color: #8B9398;
}

.calendar .fc-v-event, .calendar .fc-event {
    background-color: #59C8FA;
    border: #59C8FA;
    color: white;
    padding: 0 8px;
}

.calendar .fc-daygrid-body.fc-daygrid-body-unbalanced.fc-daygrid-body-natural {
    display: none;
}

.fc-direction-ltr .fc-timegrid-col-events{
    margin: 0;
}
@media screen and (min-width: 810px) {
    .calendar .fc-v-event, .calendar .fc-event {
        padding: 0 0.5vw;
        margin-bottom: 0.0625vw;
        border-radius: 0.1875vw;
    }

}
